import CesiumMVTImageryProvider from "cesium-mvt-imagery-provider";

export default class CustomCesiumMVTImageryProvider extends CesiumMVTImageryProvider {
	constructor(options) {
        
		options.onSelectFeature = (feature) =>  {

			if (feature) {
				console.log ("Feature is found", feature.properties);
			} else {
				console.log ("no feature found");
			}
			// Extract properties to display
			const properties = feature.properties;
			let propertyInfo = "";
			for (const [key, value] of Object.entries(properties)) {
			  propertyInfo += `<tr><td><strong>${key}</strong></td><td>${value}</td></tr>`;
			}

			// Create HTML table for InfoBox
			const infoBoxContent = `
			  <table style="border-collapse: collapse; width: 100%;">
				${propertyInfo}
			  </table>
			`;

			// Create a temporary Cesium entity
			const entity = new Cesium.Entity({
			  description: infoBoxContent, // Set the description for the InfoBox
			});

			// Set the entity as the selectedEntity to trigger the InfoBox
			if (options.viewer) {
				options.viewer.selectedEntity = entity;
				options.viewer.selectedEntity.name = properties['name'] || properties['@id'];
            } else {
                console.error("Viewer instance not provided in options.");
            }
		  };
		  
		super(options);
		this.customRectangle = options.rectangle;

    }

    requestImage(x, y, level) {
		if(this.customRectangle){
			const tileRectangle = this.tilingScheme.tileXYToRectangle(x, y, level);
			
			const intersection = Cesium.Rectangle.intersection(tileRectangle, this.customRectangle);
			
			if (!intersection 
				|| (intersection.east - intersection.west <= 0) || (intersection.north - intersection.south <= 0)) {
			//	console.log(`Skipping tile: x=${x}, y=${y}, level=${level}`);
				return undefined; // Skip request if no intersection
			}
		}
        
        return super.requestImage(x, y, level);
    }
	

}
